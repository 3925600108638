export const API_ROOT = process.env.REACT_APP_API_ROOT;
export const GQL_ENDPOINT = process.env.REACT_APP_GQL_ENDPOINT;
export const STATIC_BASE_URL = process.env.REACT_APP_STATIC_BASE_URL;
export const CX_SERVER_BASE_URL = process.env.REACT_APP_CX_SERVER_BASE_URL;
export const CX_USER_DB_BASE_URL = process.env.REACT_APP_CX_USER_DB_BASE_URL;
export const UPDATE_CUSTOMER_LAMBDA_URL =
  process.env.REACT_APP_UPDATE_CUSTOMER_LAMBDA_URL;
export const CLOUD_FUNCTIONS_BASE_URL =
  process.env.REACT_APP_CLOUD_FUNCTIONS_BASE_URL;
export const CX_SERVER_API_KEY = process.env.REACT_APP_CX_SERVER_API_KEY;
export const CX_SERVER_ACCESS_TOKEN =
  process.env.REACT_APP_CX_SERVER_ACCESS_TOKEN;
export const DEFAULT_DOC_MALE = `${STATIC_BASE_URL}/male-doctor.png`;
export const DEFAULT_DOC_FEMALE = `${STATIC_BASE_URL}/female-doctor.png`;
export const TV_DISPLAY_URL = process.env.REACT_APP_TV_DISPLAY_URL;
export const COGNITO_BASE_URL = process.env.REACT_APP_COGNITO_BASE_URL;
export const COGNITO_API_KEY = process.env.REACT_APP_COGNITO_API_KEY;
export const USER_SERVICE_URL = process.env.REACT_APP_USER_SERVICE_URL;
export const S2S_USER_SERVICES_API_KEY =
  process.env.REACT_APP_S2S_USER_SERVICES_API_KEY;
export const S2S_CX_SERVER_API_KEY =
  process.env.REACT_APP_S2S_CX_SERVER_API_KEY;
export const S2S_C9USERMORE_API_KEY =
  process.env.REACT_APP_S2S_C9USERMORE_API_KEY;

export const vcApps = ['opentok', 'zoom'];
