import classnames from 'classnames';
import gql from 'graphql-tag';
import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { FaAngleDoubleLeft, FaCog } from 'react-icons/fa';
import {
  Button,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  PopoverBody,
  PopoverHeader,
  Row,
  TabContent,
  TabPane,
  UncontrolledPopover
} from 'reactstrap';
import AuthRequired from '../components/AuthRequired';
import DoctorEditTabs from '../components/DoctorEditTabs';
import Loader from '../components/Loader';
import RenderDoctorList from '../components/RenderDoctorList';
import SyncDocsWithLT from '../components/SyncDocsWithLT';
import { getLoggedInUser } from '../permissions';
import DoctorSearchBar from './DoctorSearchBar';

export const SEARCH_DOCTORS = gql`
  query SEARCH_DOCTORS($searchInput: DoctorSearchInput) {
    getDoctors(input: $searchInput) {
      id
      name
      primarySpecialty
      entityId
      isConfigured
      profileStatus
      photos {
        dp300 {
          url
        }
      }
    }
  }
`;

export const GET_DOCTOR = gql`
  query Doctor($id: String) {
    selectedDoc: getDoctor(id: $id) {
      id
      lifetrenzId
      qwid
      languages
      dateOfJoining
      altDepartment
      altDesignationType
      aboutMe
      awards
      cloudinaryId
      seoTitle
      seoDescription
      membership
      globalRank
      dateSincePracticing
      department
      departmentId
      email
      entityId
      gender
      id
      medicalCouncilRegNo
      name
      ancCard
      ancCardSettings
      notesAbbrevations
      websiteURL
      phoneNumber
      photos {
        dp300 {
          url
        }
      }
      primarySpecialty
      qualification
      secondarySpecialty
      services
      tags
      isConfigured
      ltFirstSyncData
      ltLastSyncData
      ltCreatedAt
      ltUpdatedAt
      created_at
      updated_at
      hasOp
      hasIp
      hasOt
      followUp
      isEnabled
      isVisible
      vcApp
      siteCode
      seoSlug
      displaySpecialty
      displaySpecialtyArray
      profileStatus
      employmentType
      degree {
        name
        university
        fromDate
        toDate
      }
      fellowships {
        type
        name
        university
        fromDate
        toDate
        issuedAuthority
        issuedYear
      }
      experience {
        organisation
        designation
        fromDate
        toDate
      }
      papersPublished {
        name
        publication
        dateOfPublication
        description
      }
      approxNoOfDeliveries
      highlights
      yearsOfExperience
      hospitals: getHospitals {
        id
        name
      }
      customSmsSuffix
      coordinatorPhoneNumber
    }
    hospitals: getHospitals {
      id
      name
    }
    departments: getDepartments {
      id
      name
    }
    specializations: getSpecializations {
      id
      name
      departmentId
    }
  }
`;

const tabs = {
  new: 'new',
  pending: 'pending',
  rejected: 'rejected',
  others: 'others'
};

export default class Doctors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditSlotprotoModalOpen: false,
      showUnconfDocs: false,
      searchCriteria: {},
      activeTabForHIMS: tabs.pending,
      activeTabForBUHR: tabs.new
    };
  }

  user = getLoggedInUser();
  userRole = this.user && this.user.role;

  toggleEditSlotprotoModal = () =>
    this.setState({
      isEditSlotprotoModalOpen: !this.state.isEditSlotprotoModalOpen
    });

  addSlotType = () =>
    this.setState({ slotTypes: [...this.state.slotTypes, {}] });

  handleDoctorClick = doctorId => {
    this.setState({ selDocId: doctorId });
  };

  render() {
    const { selDocId, activeTabForHIMS, activeTabForBUHR } = this.state;
    return (
      <AuthRequired pageName="docs">
        <Container fluid>
          <Row>
            <Col sm={3}>
              <h1>Doctors</h1>
            </Col>
            {this.userRole !== 'buhr' && (
              <Col className="text-right">
                <Button color="link" id="docSettings">
                  <FaCog />
                </Button>
                <UncontrolledPopover
                  trigger="legacy"
                  placement="bottom"
                  target="docSettings"
                >
                  <PopoverHeader>Doctors Settings</PopoverHeader>
                  <PopoverBody>
                    <SyncDocsWithLT />
                  </PopoverBody>
                </UncontrolledPopover>
              </Col>
            )}
          </Row>

          <Row>
            <Col>
              <DoctorSearchBar
                onSubmit={searchCriteria => this.setState({ searchCriteria })}
              />
            </Col>
          </Row>
          <Row>
            {this.user.canApproveDoctors && (
              <Col sm={3}>
                <Nav tabs style={{ marginBottom: '1rem' }}>
                  <NavItem>
                    <NavLink
                      style={{
                        fontWeight:
                          activeTabForHIMS === tabs.pending ? 'bold' : 'normal'
                      }}
                      className={classnames({
                        active: activeTabForHIMS === tabs.pending
                      })}
                      onClick={() =>
                        this.setState({ activeTabForHIMS: tabs.pending })
                      }
                    >
                      PENDING
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{
                        fontWeight:
                          activeTabForHIMS === tabs.others ? 'bold' : 'normal'
                      }}
                      className={classnames({
                        active: activeTabForHIMS === tabs.others
                      })}
                      onClick={() =>
                        this.setState({ activeTabForHIMS: tabs.others })
                      }
                    >
                      OTHERS
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTabForHIMS}>
                  <TabPane tabId={tabs.pending}>
                    <RenderDoctorList
                      query={SEARCH_DOCTORS}
                      variables={{
                        searchInput: { ...this.state.searchCriteria }
                      }}
                      filterFunction={doctor =>
                        doctor.profileStatus === 'pending'
                      }
                      onDoctorClick={this.handleDoctorClick}
                    />
                  </TabPane>

                  <TabPane tabId={tabs.others}>
                    <RenderDoctorList
                      query={SEARCH_DOCTORS}
                      variables={{
                        searchInput: { ...this.state.searchCriteria }
                      }}
                      filterFunction={doctor =>
                        doctor.profileStatus !== 'pending'
                      }
                      onDoctorClick={this.handleDoctorClick}
                    />
                  </TabPane>
                </TabContent>
              </Col>
            )}
            {this.userRole === 'buhr' && (
              <Col sm={3}>
                <Nav tabs style={{ marginBottom: '1rem' }}>
                  <NavItem>
                    <NavLink
                      style={{
                        fontWeight:
                          activeTabForBUHR === tabs.new ? 'bold' : 'normal'
                      }}
                      className={classnames({
                        active: activeTabForBUHR === tabs.new
                      })}
                      onClick={() =>
                        this.setState({ activeTabForBUHR: tabs.new })
                      }
                    >
                      NEW
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{
                        fontWeight:
                          activeTabForBUHR === tabs.pending ? 'bold' : 'normal'
                      }}
                      className={classnames({
                        active: activeTabForBUHR === tabs.pending
                      })}
                      onClick={() =>
                        this.setState({ activeTabForBUHR: tabs.pending })
                      }
                    >
                      PENDING
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{
                        fontWeight:
                          activeTabForBUHR === tabs.rejected ? 'bold' : 'normal'
                      }}
                      className={classnames({
                        active: activeTabForBUHR === tabs.rejected
                      })}
                      onClick={() =>
                        this.setState({ activeTabForBUHR: tabs.rejected })
                      }
                    >
                      REJECTED
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTabForBUHR}>
                  <TabPane tabId={tabs.new}>
                    <RenderDoctorList
                      query={SEARCH_DOCTORS}
                      variables={{
                        searchInput: {
                          ...this.state.searchCriteria,
                          hospitalIds: this.user.hospitals
                        }
                      }}
                      filterFunction={doctor => doctor.profileStatus === 'new'}
                      onDoctorClick={this.handleDoctorClick}
                    />
                  </TabPane>
                  <TabPane tabId={tabs.pending}>
                    <RenderDoctorList
                      query={SEARCH_DOCTORS}
                      variables={{
                        searchInput: {
                          ...this.state.searchCriteria,
                          hospitalIds: this.user.hospitals
                        }
                      }}
                      filterFunction={doctor =>
                        doctor.profileStatus === 'pending'
                      }
                      onDoctorClick={this.handleDoctorClick}
                    />
                  </TabPane>
                  <TabPane tabId={tabs.rejected}>
                    <RenderDoctorList
                      query={SEARCH_DOCTORS}
                      variables={{
                        searchInput: {
                          ...this.state.searchCriteria,
                          hospitalIds: this.user.hospitals
                        }
                      }}
                      filterFunction={doctor =>
                        doctor.profileStatus === 'rejected'
                      }
                      onDoctorClick={this.handleDoctorClick}
                    />
                  </TabPane>
                </TabContent>
              </Col>
            )}
            {!this.user.canApproveDoctors && this.userRole !== 'buhr' && (
              <Col sm={3}>
                <RenderDoctorList
                  query={SEARCH_DOCTORS}
                  variables={{
                    searchInput: {
                      ...this.state.searchCriteria,
                      hospitalIds: this.user.hospitals
                    }
                  }}
                  filterFunction={null}
                  onDoctorClick={this.handleDoctorClick}
                />
              </Col>
            )}

            <Col sm={9}>
              {(!selDocId && (
                <div className="text-muted">
                  <h3>Select a doctor to edit</h3>
                  <h1>
                    <FaAngleDoubleLeft />
                  </h1>
                </div>
              )) || (
                <Query
                  query={GET_DOCTOR}
                  variables={{ id: selDocId }}
                  fetchPolicy="network-only"
                >
                  {({ loading, error, data }) => {
                    if (loading) return <Loader />;
                    if (error) return `Error!: ${error}`;
                    const { selectedDoc, departments, specializations } = data;
                    return (
                      <DoctorEditTabs
                        departments={departments}
                        doctor={selectedDoc}
                        specializations={specializations}
                      />
                    );
                  }}
                </Query>
              )}
            </Col>
          </Row>
        </Container>
      </AuthRequired>
    );
  }
}
